
import { defineComponent, PropType, ref, reactive } from 'vue'
import {
  ElDialog,
  ElButton,
  ElForm,
  ElFormItem,
  FormInstance,
  FormRules,
  ElNotification,
} from 'element-plus'

import { IUserUpdatePasswordForm } from '@/types'
import { apiErrorNotification } from '@/core/helpers'
import { IUser } from '@/types'
import { usersService } from '@/services'

export default defineComponent({
  name: 'UpdateProfilePasswordModal',

  components: {
    ElDialog,
    ElButton,
    ElForm,
    ElFormItem,
  },

  props: {
    modelValue: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    user: {
      required: true,
      type: Object as PropType<IUser>,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive<IUserUpdatePasswordForm>({
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    })

    const formValidationRules = reactive<FormRules>({
      password: [
        {
          required: true,
          message: 'Password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
      ],
      newPassword: [
        {
          required: true,
          message: 'New password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'New password length should be 10 to 64',
          trigger: 'change',
        },
      ],
      confirmNewPassword: [
        {
          required: true,
          message: 'Confirm password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Confirm password length should be 10 to 64',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value !== formValues.newPassword) {
              callback(new Error('Passwords not matches!'))
            } else {
              callback()
            }
          },
          message: 'Passwords not matches!',
          trigger: 'change',
        },
      ],
    })

    const onOpen = () => {
      formValues.password = ''
      formValues.newPassword = ''
      formValues.confirmNewPassword = ''

      formRef.value?.resetFields()
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await usersService.updatePassword(props.user.id, formValues)

        ElNotification({
          message: 'User password successfully updated!',
          type: 'success',
        })

        emit('close', true)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onClose = () => {
      if (!loading.value) {
        emit('close')
      }
    }

    return {
      onOpen,
      onClose,
      onSubmit,
      loading,
      formRef,
      formValues,
      formValidationRules,
    }
  },
})
