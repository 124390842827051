
import { defineComponent, ref, onMounted, computed, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  ElButton,
  ElIcon,
  ElNotification,
  ElCheckboxGroup,
  ElCheckbox,
  ElMessageBox,
} from 'element-plus'
import { Back, Edit, Delete, Key, Unlock } from '@element-plus/icons-vue'

import { routeNames } from '@/router'
import { EPermissions } from '@/types'
import { useUsers, usePermissions } from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'
import { localeDate } from '@/core/utils'
import { usersService } from '@/services'

import UpdateModal from './components/UpdateModal.vue'
import UpdatePasswordModal from './components/UpdatePasswordModal.vue'

export default defineComponent({
  name: 'UserView',

  components: {
    ElButton,
    ElIcon,
    Back,
    Edit,
    Unlock,
    ElCheckboxGroup,
    ElCheckbox,
    Key,
    Delete,
    UpdateModal,
    UpdatePasswordModal,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()

    const { get, user } = useUsers()
    const { groupedPermissionsWithLabels, hasPermission } = usePermissions()

    const loading = ref(true)
    const updateModalVisible = ref(false)
    const updatePasswordModalVisible = ref(false)

    const checkAll = ref(false)
    const isIndeterminate = ref(true)
    const userPermissions = ref<EPermissions[]>([])

    const userId = computed(() => {
      const userId = parseInt(route.params?.id as string)

      return !isNaN(userId) ? userId : null
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      if (!userId.value) {
        ElNotification({
          message: 'User not found!',
          type: 'error',
        })

        toUsersPage()
      }

      loading.value = true

      try {
        await get(userId.value as number)

        userPermissions.value =
          user.value?.permissions.map((permission) => permission.permission) ||
          []
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)

        toUsersPage()
      } finally {
        loading.value = false
      }
    }

    const toUsersPage = () => {
      router.push({ name: routeNames.users })
    }

    const openUpdateModal = () => {
      updateModalVisible.value = true
    }
    const closeUpdateModal = (refreshUserData: boolean) => {
      updateModalVisible.value = false

      if (refreshUserData) {
        getData()
      }
    }

    const openUpdatePasswordModal = () => {
      updatePasswordModalVisible.value = true
    }
    const closeUpdatePasswordModal = () => {
      updatePasswordModalVisible.value = false
    }

    const onAddAllPermissionsChange = (value: boolean) => {
      userPermissions.value = value ? Object.values(EPermissions) : []
      isIndeterminate.value = false
    }

    const onAddPermissionChange = (value: string[]) => {
      const checkedCount = value.length
      checkAll.value =
        checkedCount === groupedPermissionsWithLabels.value.length
      isIndeterminate.value =
        checkedCount > 0 &&
        checkedCount < groupedPermissionsWithLabels.value.length
    }

    const onSubmitUpdatePermissions = async () => {
      loading.value = true

      try {
        await usersService.updatePermissions(user.value?.id as number, {
          permissions: userPermissions.value,
        })

        ElNotification({
          message: 'User permissions successfully updated!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onSubmitDelete = async () => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to delete ',
          h(
            'span',
            { class: 'font-bold' },
            `${user.value?.firstName} ${user.value?.lastName}?`,
          ),
        ]),
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      loading.value = true

      try {
        await usersService.delete(user.value?.id as number)

        ElNotification({
          message: 'User successfully delete!',
          type: 'success',
        })

        toUsersPage()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      toUsersPage,
      user,
      localeDate,
      userPermissions,
      groupedPermissionsWithLabels,
      updateModalVisible,
      openUpdateModal,
      closeUpdateModal,
      EPermissions,
      hasPermission,
      openUpdatePasswordModal,
      closeUpdatePasswordModal,
      updatePasswordModalVisible,
      checkAll,
      isIndeterminate,
      onAddAllPermissionsChange,
      onAddPermissionChange,
      onSubmitUpdatePermissions,
      onSubmitDelete,
    }
  },
})
