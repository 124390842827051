
import { defineComponent, PropType, ref, reactive } from 'vue'
import {
  ElDialog,
  ElButton,
  ElForm,
  ElFormItem,
  FormInstance,
  FormRules,
  ElNotification,
} from 'element-plus'

import { IUserUpdateForm } from '@/types'
import { apiErrorNotification } from '@/core/helpers'
import { IUser } from '@/types'
import { usersService } from '@/services'

export default defineComponent({
  name: 'UpdateUserModal',

  components: {
    ElDialog,
    ElButton,
    ElForm,
    ElFormItem,
  },

  props: {
    modelValue: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    user: {
      required: true,
      type: Object as PropType<IUser>,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive<IUserUpdateForm>({
      firstName: '',
      lastName: '',
    })

    const formValidationRules = reactive<FormRules>({
      firstName: [
        {
          required: true,
          message: 'First name is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'First name length should be 1 to 255',
          trigger: 'change',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Last name is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'Last name length should be 1 to 255',
          trigger: 'change',
        },
      ],
    })

    const onOpen = () => {
      formValues.firstName = props.user.firstName
      formValues.lastName = props.user.lastName
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await usersService.update(props.user.id, formValues)

        ElNotification({
          message: 'User info successfully updated!',
          type: 'success',
        })

        emit('close', true)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onClose = () => {
      if (!loading.value) {
        emit('close')
      }
    }

    return {
      onClose,
      onSubmit,
      onOpen,
      loading,
      formRef,
      formValues,
      formValidationRules,
    }
  },
})
